<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="8"><DueFees></DueFees></v-col>
      <v-col cols="12" sm="4"><history></history> </v-col>
    </v-row>
    <!-- <v-row
      ><v-col cols="12" lg="8"
        ><pay @setPaymentHistory="setPaymentHistory"></pay>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
const Pay = () => import("@/components/payment/Pay.vue");
const DueFees = () => import("@/components/payment/DueFees.vue");
const History = () => import("@/components/payment/History.vue");

export default {
  components: { History, DueFees, Pay },
  data() {
    return {
      selected_room: 1,
      notices: [{ id: 1 }, { id: 2 }],
    };
  },
  created() {
    this.$store.dispatch("payment/loadFees");
  },
  computed: {},
  methods: {
    setPaymentHistory(history) {
      this.history = history;
    },
  },
};
</script>

<style></style>
